import {VariantProps, cva} from 'class-variance-authority';
import {forwardRef, useEffect, useRef, useState} from 'react';
import {cn} from '~/components/utils';
import {arrondi} from '~/legacy/utils/utils';
import {useAutResetState} from '../hooks/useAutoSaveForm';
import {FormSection} from './form-section';
import {Switch, variants} from './switch';

const inputVariants = cva(
  'flex w-full font-semibold border text-muted-foreground bg-background ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:text-muted-foreground',
  {
    variants: {
      variant: {
        default: 'border-input focus-visible:ring-ring',
        primary: 'border-primary focus-visible:ring-primary focus-visible:text-primary',
        destructive: 'border-destructive focus-visible:ring-destructive focus-visible:text-destructive',
        secondary: 'border-secondary focus-visible:ring-secondary focus-visible:text-secondary',
        tertiary: 'border-tertiary focus-visible:ring-tertiary focus-visible:text-tertiary',
        quaternary: 'border-quaternary focus-visible:ring-quaternary focus-visible:text-quaternary',
      },
      size: {
        md: 'h-10 px-3 py-2 text-sm rounded-md',
        xl: 'h-16 px-4 py-3 text-2xl rounded-2xl',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  },
);

export type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  rightElement?: React.ReactNode;
  blurOnEnter?: boolean;
} & VariantProps<typeof inputVariants>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({blurOnEnter, className, variant, rightElement, type, size, ...props}, ref) => {
    const myRef = useRef<HTMLInputElement>(null);

    return (
      <div className={cn(className, 'relative')}>
        <input type={type} className={cn(inputVariants({variant, size}))} ref={ref ? ref : myRef} {...props} />
        {!!rightElement && <div className="absolute right-2 top-2 text-muted-foreground">{rightElement}</div>}
      </div>
    );
  },
);
Input.displayName = 'Input';

export {Input};

const getStringWithThousandsSeparator = ({
  stringValue,
  editing,
  noThousandSeparator,
  round,
}: {
  stringValue: string | number | readonly string[] | undefined;
  noThousandSeparator?: boolean;
  editing?: boolean;
  round?: number;
}) => {
  if (stringValue === undefined) return '';
  if (editing) {
    return stringValue.toString().replace(' ', '');
  }
  const sDot = stringValue.toString().replace(',', '.');
  if (isNaN(Number(sDot))) {
    return stringValue.toString();
  }
  return noThousandSeparator
    ? arrondi(Number(sDot), round)
    : new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: round,
      }).format(Number(sDot));
  // let parts = sDot.split('.');
  // const numberPart = parts[0];
  // const decimalPart = parts[1];
  // const thousands = /\B(?=(\d{3})+(?!\d))/g;
  // return numberPart.replace(thousands, ' ') + (decimalPart ? ',' + decimalPart : '');
};

export type NumberInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  rightElement?: React.ReactNode;
  handleChange?: (value: number) => void;
  dontSelectOnFocus?: boolean;
  round?: number;
  noThousandSeparator?: boolean;
} & VariantProps<typeof inputVariants>;

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(({defaultValue, value, ...props}, ref) => {
  if (value !== undefined) {
    return <ControlledNumberInput value={value} {...props} ref={ref} />;
  }
  return <UncontrolledNumberInput defaultValue={defaultValue} {...props} ref={ref} />;
});

export const ControlledNumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      defaultValue,
      className,
      noThousandSeparator,
      variant,
      dontSelectOnFocus,
      rightElement,
      name,
      value = defaultValue,
      handleChange: _handleChange,
      onChange,
      onBlur,
      onFocus,
      type,
      round,
      ...props
    },
    ref,
  ) => {
    const [editing, setEditing] = useState(false);

    const [stringValue, _setStringValue] = useAutResetState(value);
    const refTextInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
      const handleEnter = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && editing) {
          event.stopPropagation();
          if (refTextInput && refTextInput.current) {
            refTextInput.current.blur();
          }
        }
      };
      window.addEventListener('keydown', handleEnter);
      return () => {
        window.removeEventListener('keydown', handleEnter);
      };
    }, [editing]);

    const setStringValue = (s: string) => {
      _setStringValue(s);
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
      const nextValue = e.target.value.toString();
      const lastChar = nextValue.slice(-1);
      const nextValueComa = nextValue.replace('.', ',').replace(' ', '');
      const nextValueDot = nextValueComa.replace(',', '.');
      if (lastChar === '.' || lastChar === ',') {
        // avoid more than one dot
        if (!isNaN(Number(`${nextValueDot}0`))) {
          setStringValue(nextValueComa);
        }
        // allows empty input (if 2 before, keeps real value = 2)
      } else if (nextValue === '') {
        setStringValue(nextValue);
      } else {
        // if ending by a digit and full string represents a valid number, set it
        if (!isNaN(Number(lastChar)) && !isNaN(Number(nextValueDot))) {
          setStringValue(nextValueComa);
          _handleChange?.(Number(nextValueDot));
          onChange?.(e);
        }
      }
    };

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = e => {
      setEditing(true);
      if (stringValue === '0') {
        setStringValue('');
      }
      if (!dontSelectOnFocus) {
        setTimeout(() => refTextInput.current?.select(), 100);
      }
      onFocus?.(e);
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = e => {
      setEditing(false);
      if (stringValue === '') {
        setStringValue('0');
        _handleChange?.(0);
      }
      onBlur?.(e);
    };

    return (
      <div className={cn(className, 'relative')}>
        <input
          ref={refTextInput}
          type="text"
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={getStringWithThousandsSeparator({stringValue, editing, round, noThousandSeparator})}
          onChange={handleChange}
          className={cn(inputVariants({variant}))}
          {...props}
        />
        <input type="hidden" value={value} ref={ref} name={name} />
        {!!rightElement && <div className="absolute right-2 top-2 text-muted-foreground">{rightElement}</div>}
      </div>
    );
  },
);

export const UncontrolledNumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      defaultValue,
      className,
      noThousandSeparator,
      variant,
      dontSelectOnFocus,
      rightElement,
      name,
      handleChange: _handleChange,
      onChange,
      onBlur,
      onFocus,
      type,
      round,
      ...props
    },
    ref,
  ) => {
    const [editing, setEditing] = useState(false);

    const [value, setValue] = useAutResetState(defaultValue);
    const [stringValue, _setStringValue] = useAutResetState(defaultValue);
    const refTextInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
      const handleEnter = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && editing) {
          event.stopPropagation();
          if (refTextInput && refTextInput.current) {
            refTextInput.current.blur();
          }
        }
      };
      window.addEventListener('keydown', handleEnter);
      return () => {
        window.removeEventListener('keydown', handleEnter);
      };
    }, [editing]);

    const setStringValue = (s: string) => {
      _setStringValue(s);
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
      const nextValue = e.target.value.toString();
      const lastChar = nextValue.slice(-1);
      const nextValueComa = nextValue.replace('.', ',').replace(' ', '');
      const nextValueDot = nextValueComa.replace(',', '.');
      if (lastChar === '.' || lastChar === ',') {
        // avoid more than one dot
        if (!isNaN(Number(`${nextValueDot}0`))) {
          setStringValue(nextValueComa);
        }
        // allows empty input (if 2 before, keeps real value = 2)
      } else if (nextValue === '') {
        setStringValue(nextValue);
      } else {
        // if ending by a digit and full string represents a valid number, set it
        if (!isNaN(Number(lastChar)) && !isNaN(Number(nextValueDot))) {
          setStringValue(nextValueComa);
          setValue?.(Number(nextValueDot));
          onChange?.(e);
        }
      }
    };

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = e => {
      setEditing(true);
      if (stringValue === '0') {
        setStringValue('');
      }
      if (!dontSelectOnFocus) {
        setTimeout(() => refTextInput.current?.select(), 100);
      }
      onFocus?.(e);
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = e => {
      setEditing(false);
      if (stringValue === '') {
        setStringValue('0');
        setValue?.(0);
      }
      onBlur?.(e);
    };

    return (
      <div className={cn(className, 'relative')}>
        <input
          ref={refTextInput}
          type="text"
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={getStringWithThousandsSeparator({stringValue, editing, round, noThousandSeparator})}
          onChange={handleChange}
          className={cn(inputVariants({variant}))}
          {...props}
        />
        <input type="hidden" value={value} onChange={() => {}} ref={ref} name={name} />
        {!!rightElement && <div className="absolute right-2 top-2 text-muted-foreground">{rightElement}</div>}
      </div>
    );
  },
);

ControlledNumberInput.displayName = 'ControlledNumberInput';
UncontrolledNumberInput.displayName = 'UncontrolledNumberINput';

type SwitchInputProps = {
  value: number;
  valueIfAuto: number;
  name: string;
  labelAuto?: string;
  className?: string;
  isAutoDefault: boolean;
  isAutoName: string;
  round?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  setValue: React.Dispatch<React.SetStateAction<number>>;
} & VariantProps<typeof variants>;
export const SwitchedInput = ({
  value,
  setValue,
  name,
  isAutoDefault,
  isAutoName,
  className,
  valueIfAuto,
  labelAuto = 'auto',
  round,
  variant,
  onChange,
}: SwitchInputProps) => {
  const [isAutoValue, setIsAutoValue] = useAutResetState(isAutoDefault);

  const handleIsAutoChange = (b: boolean) => {
    setIsAutoValue(b);
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = Number(e.target.value.replace(',', '.'));
    setIsAutoValue(false);
    setValue(nextValue);
    onChange?.(e);
  };

  useEffect(() => {
    if (isAutoValue) {
      setValue(valueIfAuto);
    }
  }, [isAutoValue, valueIfAuto]);

  return (
    <div className={cn('flex flex-row gap-2 items-center', className)}>
      <FormSection className="flex-2 gap-0" label={labelAuto} orientation={'horizontal-reverse'}>
        <Switch variant={variant} checked={isAutoValue} onCheckedChange={handleIsAutoChange} name={isAutoName} />
      </FormSection>
      <NumberInput
        className="flex-2"
        value={value}
        round={round}
        onChange={handleValueChange}
        name={name}
        rightElement="€"
      />
    </div>
  );
};
